import { FC } from 'react';
import IconProps from './IconProps';

const FunnelIcon: FC<IconProps & { dot?: boolean }> = (props) => {
  const { dot, onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  const attr = { onClick: onClick, className: `inline ${cursor} ${className}` };

  return (
    <svg {...attr} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2.66676C2 2.29852 2.29852 2 2.66676 2H13.335C13.7032 2 14.0017 2.29852 14.0017 2.66676V4.39087C14.0017 4.5677 13.9314 4.7373 13.8064 4.86234L9.52968 9.13909C9.40467 9.26411 9.33439 9.43374 9.33439 9.61056V11.3347L6.66734 14.0017V9.61056C6.66734 9.43374 6.59709 9.26411 6.47205 9.13909L2.19529 4.86234C2.07025 4.7373 2 4.5677 2 4.39087V2.66676Z"
        stroke="currentColor"
        strokeWidth="1.33353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {dot && <circle cx="2.5" cy="2.5" r="2.5" transform="matrix(1 0 0 -1 12 5)" fill="#2C7DAB" />}
    </svg>
  );
};

export default FunnelIcon;
